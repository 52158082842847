import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import i18nMessages, { Translations, Language } from '~/i18n/messages';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
  require('@formatjs/intl-pluralrules/locale-data/ja');
}

if (!(Intl as any).RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en');
  require('@formatjs/intl-relativetimeformat/locale-data/ja');
}

type ProviderProps = {
  children: ReactNode | any;
  locale: Language;
};

export const Provider: React.FC<ProviderProps> = ({ children, locale }) => {
  const messages: Translations = i18nMessages[locale];

  return (
    <IntlProvider
      defaultLocale="en"
      locale={locale}
      textComponent={React.Fragment}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};
